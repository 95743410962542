export const environment = {
  production: true,
  reportUrl:'https://multi-portal-cpaas-report.terragoncdp.com/api/v1',
  apiUrl: 'https://cpaas-portal-prod.terragonltd.com',
  tempFileUploadUrl: 'https://devcpaas.terragonltd.com',
  serverUrl: 'https://dev-cpaas.terragonltd.com',
  clientid: 'cpaas-client-dev',
  paystackPublicKey: 'pk_live_4b18564201f0ac69863f564dc69b43f21947b02e',
  facebookAppId: '295412412010020',
  trackingcode: 'G-5V6B0BMCNF',
  fbCapiInfo: 'https://fbcapiqa.terragoncdp.com/primefbcapiregistration',
  flutterWavePublicKey: 'FLWPUBK-449ea1773ad109287abbcaf866e59d38-X',
  audiencePath: 'dev:audience:msisdn:daas:alias',
  envVaribale: 'prod',
};


